<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<ngx-ui-loader></ngx-ui-loader>
<p-dialog header="" [(visible)]="displaySessionTimeout" [style]="{width: '15vw'}" [baseZIndex]="10000" [closeOnEscape]="false" 
[modal]="true" [showHeader]="false">
   <p>Session Expired </p><br>
   <p-footer>
       <p-button (click)="sessionPopup()" label="Close"></p-button>
   </p-footer>
</p-dialog>