import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';

import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HomeV2Component } from './home-v2/home-v2.component';
import { AuthGuard } from './auth.guard';
import { DataexportComponent } from './dataexport/dataexport.component';
import { ApprovalComponent } from './approval/approval.component';
import { RejectionComponent } from './rejection/rejection.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeV2Component },
  { path: 'verify', component: OtpVerificationComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  // { path: 'bot-config', component: BotComponent },
  {
    path: 'default-dashboard',
    loadChildren: () => import('./businessdashboard-v4/businessdashboard-v4.module').then(m => m.BusinessDashboardModule),
     canActivate: [AuthGuard]
  },
  {
    path: 'landingPage',
    loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  // {
  //   path: 'healthcare-dashbaord',
  //   loadChildren: () => import('./unused-components/healthcare-dashboard/healthcare-dashboard.module')
  //.then(m => m.HealthcareDashboardModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'business-dashboard',
    loadChildren: () => import('./jsondashboard/jsondashboard.module').then(m => m.JSONdashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
    canActivate: [AuthGuard]
  },
  // { path: 'unused', loadChildren: () => import('./unused-components/unused-components.module').then(m => m.UnusedComponentsModule) },
  {
    path: 'dataExport',
    //loadChildren: () => import('./dataexport/dataexport.module').then(m => m.DataexportModule),
    component: DataexportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dynamicReport',
    loadChildren: () => import('./dynamicreport/dynamicreport.module').then(m => m.DynamicreportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./help-v1/help-v1.module').then(m => m.HelpV1Module),
    canActivate: [AuthGuard]
  },
  { path: 'approve', component: ApprovalComponent },
  { path: 'reject', component: RejectionComponent },
  {
    path: 'integrationgateway',
    loadChildren: () => import('./integrationgateway/integrationgateway.module').then(m => m.IntegrationgatewayModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      useHash: true,
    },
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
