import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router, ActivatedRoute } from '@angular/router';
// import { SwUpdate } from '@angular/service-worker';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { browser } from 'protractor';
import { UserService } from 'src/app/services/user.service';
import { UserDeviceService } from 'src/app/services/user-device.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'NorthStar Framework';
  loaded = false;
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  displaySessionTimeout = false;

  constructor(private idle: Idle, private keepalive: Keepalive, private cd: ChangeDetectorRef, private router: Router) {
    // set idle parameters
    idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(1740); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      console.log('IDLE');
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      // console.log(`${this.idleState} ${new Date()}`)
      this.countdown = null;
      cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      if(window.location.hash != "#/home"){
        this.displaySessionTimeout = true;
        // if(confirm("Session expired.")){
        //   localStorage.clear();
        //   this.router.navigate(['/home']);
        // } else {
        //   localStorage.clear();
        //   this.router.navigate(['/home']);
        // }
        this.idleState = "TIMED_OUT";
        this.reset();
      } else {
        this.reset();
      }
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe(seconds =>{
      // console.log('Session will expire in', seconds, 'second');
      this.countdown = seconds
    });

    // set keepalive parameters, omit if not using keepalive
    keepalive.interval(15); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
   }

  ngOnInit() {
    this.reset();
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    let url = window.location.hash;
    let inviteUrl = url.includes("home?email");
    let verifyUrl = url.includes("verify?email");
    if(inviteUrl){
      // this.clearPreviousLoggedInData();
      localStorage.clear();
    }    
    if(isLoggedIn && !inviteUrl && !verifyUrl){
      if(url==""){
        window.location.href = window.location.origin +"/#/"+"business-dashboard";
      }
      else{
        window.location.href = window.location.origin +"/"+url;
      }
     
    }
    // window.addEventListener('load', function (event) {
    //   console.log("event",event)
    //     sessionStorage.removeItem('tChecks');
    // });
    // if (sessionStorage.getItem('tChecks')) {
    //     // sessionStorage.clear();
    //     window.location.href = window.location.origin + "/#/" + "alert";
    //     // alert('Found a lock in session storage. The storage was cleared.');
    // }  
    // sessionStorage.removeItem('tChecks');
    // const broadcast = new BroadcastChannel('northstar_channel')
    // broadcast.postMessage('I am First');
    // broadcast.onmessage = (event) => {
    //   if (event.data === "I am First") {
    //     broadcast.postMessage(`Sorry! Already open`);
    //     alert("First Tab");
    //   }
    //   if (event.data === `Sorry! Already open`) {
    //     // alert("Duplicate Tab");
    //     let value = sessionStorage.getItem('tChecks');
    //     let userData = localStorage.getItem('userData');
    //     // let status = this.loggedInStatusService.getValue();
    //     if(!value){
    //       window.location.href = window.location.origin + "/#/" + "alert";
    //     }
    //     // window.open(location, '_self').close();
    //     // window.close();
    //   }
    // };
    // localStorage.openpages = Date.now();
    // var onLocalStorageEvent = function (e) {
    //   if (e.key == "openpages") {
    //     // this.loggedInStatusService.setValue("loggedIn");
    //     localStorage.page_available = Date.now();
    //     sessionStorage.setItem('tChecks', JSON.parse('1'));
    //     // sessionStorage.setItem('tabCount', '1');
    //     this.loaded = true;
    //     // this.loggedInStatusService.setValue('loggedIn');
    //   }
    //   if (e.key == "page_available") {
    //     // this.signupService.loggedIn.next(false);
    //     // let count = parseInt(sessionStorage.getItem('tabCount')); 
        
    //     let value = sessionStorage.getItem('tChecks');
    //     let userData = localStorage.getItem('userData');
    //     // let status = this.loggedInStatusService.getValue();
    //     if(!value){
    //       window.location.href = window.location.origin + "/#/" + "alert";
    //     }
    //     // this.router.navigateByUrl(window.location.origin + '/' + 'alert');
    //     // alert("One more page already open");
    //   }
    // };
    // window.addEventListener("storage", onLocalStorageEvent, false);
    // if (this.swUpdate.isEnabled) {
    //     this.swUpdate.available.subscribe(() => {
    //         if(confirm("New version available. Load New Version?")) {
    //             window.location.reload();
    //         }
    //     });
    // }
  }

  // deviceInfo:any = [];
  // clearPreviousLoggedInData(){
  //   this.userDeviceService.shareDeviceDetails.subscribe(
  //     (resp: any) => {
  //       console.log('Device Info', resp);
  //       this.deviceInfo = resp;
  //     }
  //   );
  //   const req = {
  //     userLogoutIp: this.deviceInfo?.IP,
  //     logoutBrowser: this.deviceInfo?.deviceInfo?.browser
  //     //Comment for now in prod deployment
  //   };
  //   this.userService.logoutUser(req)
  //     .subscribe((res: any) => {
  //       if (res.status.statusMsg === 'Success') {
  //         localStorage.clear();
  //       }
  //   })
  // }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  sessionPopup(){
    this.displaySessionTimeout = false;
    localStorage.clear();
    this.router.navigate(['/home']);
  }
}
