import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Config } from 'protractor';
import { map, publishReplay, refCount } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class BaseService {
  loginStatusSubject = new BehaviorSubject('');
  configs: Observable<Config[]>;

  constructor(private http: HttpClient) { }

  postData(url, data) {
    return this.http.post(`${environment.mainurl}${url}`, data);
  }

  getData(url) {
    return this.http.get(`${environment.mainurl}${url}`);
  }
  getDataOnUserType(url) {
    return this.http.get(`${environment.mainurl}${url}`);
  }
  stateEmitter(param) {
    this.loginStatusSubject.next(param);
  }
  postProfileData(email, role, businessId) {
    return this.http.get(environment.mainurl+'profile/getUserProfileV1?userEmail='+email+'&userRole='+role+'&businessId='+businessId);
  }

  postProfileDataV1(email, role, businessId) {
    return this.http.get(environment.mainurl+'profile/getUserProfileV1?userEmail='+ btoa(email)+'&userRole='+ btoa(role)+'&businessId='+ btoa(businessId));
  }

  deleteBusinessLogo(email,businessId){
    return this.http.delete(environment.mainurl+'profile/deleteBusinessLogo?userEmail='+ email+'&businessId='+businessId);
  }

  getBussinessData(){
    return this.http.get(environment.mainurl+'profile/getBusinessNameById');
  }

  getUAMSearvice(){
    return this.http.get(environment.mainurl+'checkUAMService');
  }

  getHelpData(type){
    return this.http.get(environment.mainurl+'help/findByContentType?contentType='+type);
  }

  advanceSearch(contentType, text){
    return this.http.get(`${environment.mainurl}help/advanceSearch?contentType=${contentType}&text=${text}`);
  }

  tourDefaultSetting(){
    return this.http.post(environment.mainurl+'profile/setTourFunctionality', {});
  }

  getManagerList(type){
    return this.http.get(environment.mainurl+'profile/getManagerList?businessId='+type);
  }

  getManagerListV1(type){
    return this.http.get(environment.mainurl+'profile/getManagerList?businessId='+btoa(type));
  }

  getIPAddress(){
    return this.http.get('https://api.ipify.org/?format=json',{ headers: null });
  }

  backupBusiness(req){
    return this.http.post(environment.businessDelete+'saveBusinessDeleteConfirmationDetails', req);
  }

  cancelInvite(req){
    return this.http.post(environment.mainurl+'cancelInvitation', req);
  }

  resetLoginAttempt(email,businessId){
    return this.http.get(environment.mainurl+'profile/resetUserLoginCount?emailId='+ email + '&businessId=' + businessId);
  }

  // hotReadsDetails(req){
  //   return this.http.post(environment.hotreadsUrl+'saveEmailFromHRVideo', req);
  // }

  generateShortURL(req){
    return this.http.post(environment.shortUrl+'createShortUrl', req);
  }

  getBusinessLabels(req){
    return this.http.post(environment.mainurl+'custom-dashboard/getBusinessUserLabels', req);
  }
  getBusinessLabelsV1(json){
    const req = { data: btoa(JSON.stringify(json)) }
    return this.http.post(environment.mainurl+'custom-dashboard/getBusinessUserLabelsV1', req);
  }

  updateHeaderLogo(req){
    return this.http.post(environment.mainurl+'businessHeader/updateHeaderData', req);
  }

  getHeaderLogo(businessId){
    return this.http.get(environment.mainurl+'businessHeader/getHeaderData?businessId='+businessId);
  }

  getHeaderLogoV1(businessId){
    return this.http.get(environment.mainurl+'businessHeader/getHeaderDataV1?businessId='+ btoa(businessId));
  }

  /* getAnswersByTopicId(id){
    return this.http.get(environment.mainurl+"help/getTopicAnswerById?objectId="+id);
  } */

  // Get configs from server | HTTP GET
  getAnswersByTopicId(id): Observable<Config[]> {

    // Cache it once if configs value is false
    if (!this.configs) {
       this.getAnswerCache(id);
    }
    else{
      this.configs.subscribe(res=>{
        let hasMatch:boolean=false;
        res.forEach(data=>{
          if(data.id == id){
            hasMatch = true;
            return;
          }
        })
        if(hasMatch==false){
          this.configs = this.http.get(`${environment.mainurl}`+"help/getTopicAnswerById?objectId="+id).pipe(
            map(response => {
                this.result.push({id:response['responseData']['id'], answer: response['responseData']['answer']});
              return this.result;
            }),
              publishReplay(), // this tells Rx to cache the latest emitted
              refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
          );
        }
      })
    }
    return this.configs;
}
result = [];
getAnswerCache(id){
  this.configs = this.http.get(`${environment.mainurl}`+"help/getTopicAnswerById?objectId="+id).pipe(
    map(response => {
        this.result.push({id:response['responseData']['id'], answer: response['responseData']['answer']});
      return this.result;
    }),
      publishReplay(), // this tells Rx to cache the latest emitted
      refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
  );
}

getHelpDataConfig(businessId) {
  return this.http.get(environment.mainurl + `help/getHelpDataConfig?businessId=${businessId}`);
}

getUserData(email,bId){
  return this.http.get(environment.mainurl+"profile/getUserData?userEmail="+email+"&businessId="+bId);
}

getUserDataV1(email,bId){
  return this.http.get(environment.mainurl+"profile/getUserDataV1?userEmail="+ btoa(email)+"&businessId="+btoa(bId));
}

getBusinessDeleteAccountDetails(req){
  return this.http.get(environment.chatBaseUrl+'BDU/getBusinessDeleteAccountDetails/'+req);
}

getSearchUser(bId,key){
  return this.http.get(environment.mainurl+"profile/searchUser?businessId="+bId+"&keyword="+key);
}
nsUserRoleUpdate(req){
  return this.http.post<any>(`${environment.mainurl}nsUserRoleUpdate`, req);
}

getExportExcelData(bId){
  return this.http.get<any>(environment.mainurl + "profile/getAllPaginatedUsersInfoV1?businessId="+btoa(bId), {responseType: 'blob' as 'json'});
}
}
