import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, } from '@angular/core';
import { Galleria } from 'primeng/galleria';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TourComponent implements OnInit {
  @ViewChild('galleria') galleria: Galleria;
  @Input() displayTourContent: boolean;
  @Output() cancelTourEmit = new EventEmitter<boolean>();
  counts: any = [];
  currentPage = 0;
  showThumbnails: boolean;
  activeIndex = 0;
  onFullScreenListener: any;
  checked = false;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  displayCheckbox = true;
  constructor(private baseService: BaseService) {

  }


  ngOnInit() {
    for (let i = 1; i <= 41; i++) {
      this.counts.push(i);
    }
    this.displayCheckbox = JSON.parse(localStorage.getItem('userPData')).tourFlag;
    //console.log(this.counts)
  }

  checkClose() {
    this.currentPage = 0;
    this.cancelTourEmit.emit(false);
  }

  disableTour(): void {
    if(this.checked){
      this.baseService.tourDefaultSetting().subscribe(
        (res: any) => {
          if(res.status.statusMsg === 'SUCCESS'){
            this.checkClose();
            const userPData = JSON.parse(localStorage.getItem('userPData'));
            userPData.tourFlag = this.checked;
            localStorage.setItem('userPData',JSON.stringify(userPData));
            this.displayCheckbox = JSON.parse(localStorage.getItem('userPData')).tourFlag;
          }
        }
      );
    }
  }

}
