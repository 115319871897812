//NsFarm urls =>
const baseUrl = 'nsg/NorthStar/Core/';
const chatBaseUrl = '';
const signInUrl = 'nsg/';
const userCountUrl = 'nsg/NorthStar/Core/';
const communicationDetailBCS = 'nsg/NorthStar/BCS/contextDetails/addBusinessCommunicationDetails';
const confDataURL = 'nsg/NorthStar/BCS/contextDetails/'; /* fetchAllBusinessCommunicationDetailsById */
const deleteUser = 'https://lumen-ns.straviso.net/DeleteBusiness/api/Values'; // not in use
const northstarproductUrl = '#/';
const northstarcustomermanagementUrl= '#/landingPage/customer-management';
const integrationgetwayUrl = 'ig/api/config/';

const botConfig =  'myworkbench/botConfig/';
const lumenwebapi = 'lumenwebapiv6/api/';
const chatUser = 'chat/service/';
// main page
const botInteractions = 'https://lumen-ns.straviso.net/LumenRedisKafka/api/RedisData'; //not in use

//operational portal urls - not used
const clinkReporting = 'chat/adminService/';
const buzzReporting = 'bag/';
const lumenRporting = 'lumenreportingapi/api/';
const apiDetails = 'lumenreportingapi/api/';
const fieldServeSummary = 'https://fieldserve-ns.straviso.net/index.php?r=OperationalPortalApi/GetFieldServeSummary';
const feedbackCount = 'https://fieldserve-ns.straviso.net/index.php?r=OperationalPortalApi/GetStatusFeedbackCount';
const fieldUsage = 'https://fieldserve-ns.straviso.net/index.php?r=OperationalPortalApi/GetModuleWiseCount';
const taskEasyReporting = 'teg/taskEasy/Core/operationPortal/';
const interactionUrl = 'interaction/#/dashboard';

//Service urls - UI
const customerManagementRoot = 'cm/';
const calstraUrl = '';
const amstrckUrl = 'amtrack/';
const knowMeUrl = 'knowme/';
const ticketingUrl = 'ticket/';
const workorderUrl = 'workorder/';
const assessmentUrl = 'assessment/';
const partnerUrl = 'partner/';
const myPulseUrl = 'mypulse/';
//Products urls - UI
const nexusUrl = 'nexus/chat/#/?token=';
const lumenUrl = 'lumen/';
const buzzUrl = 'buzz/';
const fieldServeUrl = 'fserve/';
const taskEasyUrl = 'task/';

//lumen chat url
const lumenChatUrl = 'lumenchat/';

// Business dashboard APIs
const workorderDashUrl = 'wo/';
const nexusDashUrl = 'nexusreports/nexusCockpit/';
const knowmeDashUrl = 'knmapi/';
const fieldserveDashUrl = 'fserve/';
const amtrackDashUrl = 'amtrack/';
const appointmentdashUrl = '';
const buzzDashUrl = 'bag/';
const taskDashUrl = 'teg/';
const ticketDahsUrl ='tmg/';
const uamUrl = 'uam/';


const customerMDashUrl = 'cmapi/';

// contact us
const contactusUrl = 'https://straviso.com/contact-us/';

//Interaction Count URL
const interactionCount  = 'interactionanalytics/';

//Azure urls
const azureBaseUrl = 'nsauth/';
const redirectUrl = 'https://nsdev.straviso.net/_hash_/home/';

const nboBusiness = 'SVS0001029';
const businessDelete = 'BDU/';

const shortUrl = 'https://nsf-nsdevext.straviso.net/sug/';
const nexusMonitorUrl = 'nexus/chat/#/monitor/?token=';
const exceptionUrl = 'nsg/NorthStar/Operations/exceptionLogger/';
const myworkbench = 'decision/chat/myworkbench/';
const errorBoardlumen = 'lumenrestserver/';
const nineDotBaseUrl = '';
const dataExportUrl = '/#/dataExport';
const dynamicReportUrl = '/#/dynamicReport';
const attUrl = 'nsauth/auth/';

export const environment = {
  production: false,
  mainurl: baseUrl,
  chatBaseUrl,
  signInUrl,
  userCountUrl,
  imgBaseUrl: 'https://northstar-images.straviso.net/',
  deleteUser,
  northstarproductUrl,
  northstarcustomermanagementUrl,
  botConfig,
  lumenwebapi,
  chatUser,
  dataExportUrl: dataExportUrl,
  dynamicReportUrl: dynamicReportUrl,
  bcsCommunicationDetail : communicationDetailBCS,
  confURL : confDataURL,
  chatAPI: 'https://northstar-app.straviso.net:8182',
  socAPI: 'messaging',
  loginUser: 'chat/service/loginUser',
  clinkReporting,
  lumenRporting,
  apiDetails,
  botInteraction: botInteractions,
  buzzReporting,

  fieldServeSummary,
  feedbackCount,
  fieldUsage,
  taskEasyReporting,

  customerManagementRoot,
  calstraUrl,
  knowMeUrl,
  amstrckUrl,
  ticketingUrl,
  workorderUrl,
  assessmentUrl,
  partnerUrl,
  myPulseUrl,
  uamBaseUrl: uamUrl,

  lumen: lumenUrl,
  nexus: nexusUrl,
  fieldServe: fieldServeUrl,
  buzz: buzzUrl,
  taskEasy: taskEasyUrl,

  lumenChatUrl,
  // for businbess Dashboard
  workorderDashUrl,
  nexusDashUrl,
  knowmeDashUrl,
  fieldserveDashUrl,
  amtrackDashUrl,
  appointmentdashUrl,
  buzzDashUrl,
  taskDashUrl,
  ticketDahsUrl,
  customerMDashUrl,
  contactusUrl,
  interactionUrl,
  interactionCount,
  integrationgetwayUrl,
  azureBaseUrl,
  azureuserloginurl:azureBaseUrl + 'oauth2/authorize/azure?redirect_uri=' + redirectUrl,
  googleuserloginurl: azureBaseUrl + 'oauth2/authorize/google?redirect_uri=' + redirectUrl,
  facebookuserloginurl: azureBaseUrl + 'oauth2/authorize/facebook?redirect_uri=' + redirectUrl,
  linkedinuserloginurl: azureBaseUrl + 'oauth2/authorize/linkedin?redirect_uri=' + redirectUrl,
  nboBusiness,
  businessDelete,
  shortUrl,
  redirectUrl,
  nexusMonitorUrl,
  exceptionUrl,
  myworkbench,
  errorBoardlumen,
  nineDotBaseUrl,
  attUrl
};
