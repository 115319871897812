import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupService } from '../services/signup.service';
import { MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BaseService } from '../services/base.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
// declare let JSEncrypt: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [MessageService,
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class ResetPasswordComponent implements OnInit {
  resetPassForm: FormGroup;
  submitted = false;
  cPassError = false;
  emailFromUrl = '';
  secreteKey: any;
  fieldTextType1: boolean;
  fieldTextType: boolean;
  validToken: boolean = true;
  constructor(
    private fb: FormBuilder,
    private activatedRouter: ActivatedRoute,
    private signUpService: SignupService,
    private messageService: MessageService,
    private router: Router,
    public loader: NgxUiLoaderService,
    private location: Location,
    private ngZone: NgZone,
    private base: BaseService
  ) { }

  ngOnInit() {
    this.loader.start();
    this.getUserEmail();
    this.signUpService.loggedIn.next(false);
    this.resetPassForm = this.fb.group({
      email: [this.emailFromUrl, [Validators.required, Validators.email]],
      newpassword: ['', [Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]
      ],
      confirmpassword: ['', [Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]]
    });
    // this.getSecretKey()
    this.location.replaceState('#/reset-password');
  }

  get f() {
    return this.resetPassForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.resetPassForm.invalid) {
      return;
    }

    if (this.resetPassForm.value.newpassword !== this.resetPassForm.value.confirmpassword) {
      this.cPassError = true;
      return;
    } else {
      this.cPassError = false;
    }
    this.loader.start();
    // code for encryption
    // let encrypt = new JSEncrypt();
    // const publickey = this.secreteKey;
    // const decryptDataRow = this.resetPassForm.value['newpassword'];
    // encrypt.setPublicKey(publickey);
    // let encryptedData = encrypt.encrypt(decryptDataRow);
    // this.resetPassForm.value['newpassword'] = encryptedData;
    const req = {
      emailId: this.resetPassForm.value.email,
      userPassword: this.resetPassForm.value.newpassword,
      forgotPassword: 'Y',
      businessId : this.userData.businessId
      // isRequestFromNS : true
    };
    this.signUpService.setPassword(req).subscribe((res: any) => {
      this.loader.stop();
      if (res && res.status.statusCode === 2000) {
        this.messageService.add({ severity: 'success', summary: 'Password reset successfully', life: 3000 });
        setTimeout(() => {
          this.router.navigateByUrl('/home');
        }, 3000);
        // this.ngZone.runOutsideAngular(() => {
          
        // });

      } else {
        this.messageService.add({ severity: 'warn', summary: 'Something went wrong!', life: 3000 });
      }

    }, err => {
      this.loader.stop();
      console.log(err);
      this.messageService.add({ severity: 'error', summary: err, life: 3000 });
    });
  }

  getUserEmail() {
    this.activatedRouter.queryParams.subscribe(params => {
      this.loader.stop();
      if (params) {
        this.verifyUserEmailLink(params.token);
        // this.emailFromUrl = params.userEmail;
      }
    });
  }

  goBack() {
    this.router.navigateByUrl('/home');
  }

  getSecretKey(){
    this.signUpService.getSecretKey().subscribe(
      (res: any) => {
        if(res.message === 'success'){
          this.secreteKey = res?.data;
          // console.log("SecretKey", this.secreteKey);
        }
      },
      err=>{
        this.messageService.add({ severity: 'warn', sticky: true,
        summary: 'Something went wrong. Please contact NBOSupport for further assistance.' });
      }
    );
  }

  toggleFieldTextType1(){
    this.fieldTextType1 = !this.fieldTextType1;
  }

  toggleFieldTextType(){
    this.fieldTextType = !this.fieldTextType;
  }

  userData:any;
  verifyUserEmailLink(token){
    this.loader.start();
    this.base.getData(`verifyUserEmailLink?token=${token}`)
      .subscribe((res: any) => {
        this.loader.stop();
        if (res.status.statusMsg === 'Success') {
          this.validToken = true;
          this.userData = res?.data;
          this.resetPassForm.controls['email'].setValue(this.userData.emailId);
          this.emailFromUrl = this.userData.emailId;
        //   this.signUpForm.patchValue({
        //     businessName: localStorage.getItem('businessName') ? localStorage.getItem('businessName') : this.userprofileData.businessName,
        //     industry: this.userprofileData.industry ? this.userprofileData.industry : undefined,
        //     employee: this.userprofileData.employees ? this.userprofileData.employees : undefined,
        //     country: this.userprofileData.country,
        //     emailId: email,
        //     firstName: this.userprofileData.firstname ? this.userprofileData.firstname : '',
        //     lastName: this.userprofileData.lastname ? this.userprofileData.lastname : '',
        //   });
        //   console.log(this.signUpForm);
        } else if(res.status.statusMsg === 'Varification Link is expired or invalid') {
          this.validToken = false;
          // this.messageService.add({ severity: 'warn', summary: `${res.status.statusMsg}`, life: 2000 });
          this.messageService.add({ severity: 'warn', summary: res.status.statusMsg, life: 3000 });
        }
      }, err => {
        this.validToken = false;
        this.loader.stop();
        // console.log(err);
        // this.messageService.add({ severity: 'warn', summary: err, life: 3000 });
      });
  }

  redirectToHome(){
    this.router.navigateByUrl('/home');
  }
  
}
